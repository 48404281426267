body {
  background-color: #35363a;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
}

.vertical-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
