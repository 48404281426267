.header-bar {
    min-width: 100%;
    height: 80px;
    background-color: white;
    display: flex;
    align-items: center;
    position: relative;
}

.header {
    display: flex;
    align-items: center;
}

.header-site-title {
    font-size: 55px;
    width: 525px;
    margin: 0px;
}

.site-title-bg {
    /* background-color: wheat; */
    background-image: linear-gradient(to right, wheat, wheat, wheat, white);
    min-width: 600px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    text-decoration: none;
    color: black;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.site-title-bg:hover {
    background-image: linear-gradient(to right, rgb(255, 220, 154), rgb(255, 220, 154), rgb(255, 220, 154), white);
    cursor: pointer;
}

.header-nav-buttons {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.header-nav-button {
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    color: black;
    background-color: white;
    display: flex;
    align-items: center;
}
.header-nav-button:hover {
    background-color: rgb(245, 245, 245);
    cursor: pointer;
}

.primary-nav-button {
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    color: black;
    background-color: rgb(86, 204, 204);
    transition: background-color 0.25s ease;
    display: flex;
    align-items: center;
}
.primary-nav-button:hover {
    background-color: rgb(62, 172, 172);
    cursor: pointer;
}

.header-nav-label {
    font-size: 26px;
    font-family: 'Roboto', sans-serif;
}
.header-nav-icon {
    margin-left: 4px;
    color: green;
    font-size: 36px !important;
}
.header-nav-button:hover .header-nav-icon {
    color: orange;
}

.disable-select {
    user-select: none;
}

.mobile-nav-dropdown {
    display: none;
}

.mobile-header-nav-buttons {
    display: none;
}
.header-nav-button.m-one {
    display: none;
}
.header-nav-button.m-two {
    display: none;
}
.header-nav-button.m-three {
    display: none;
}
.header-nav-button.m-four {
    display: none;
}
.primary-nav-button.m-five {
    display: none;
}

@media(max-width: 1800px) {
    .header-nav-button.one {
        display: none;
    }
    .header-nav-button.m-one {
        display: inherit;
    }

    .mobile-nav-dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        margin: 0px;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 80px;
        color: black;

        background: none;
        border: none;
        cursor: pointer;
        outline: none;
    }

    .mobile-header-nav-buttons.shown {
        position: absolute;
        top: 100%; /* Position it just below the hamburger menu button */
        left: 75%;
        width: 25%; 
        background-color: #fff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 10; /* Ensure the dropdown is on top of other content */
        display: flex;
        flex-direction: column;
    }

    .mobile-header-nav-buttons > * {
    /* Add any styling for the dropdown menu items here */
    padding: 8px 16px;
    text-align: center;
    cursor: pointer;
    }
}

@media(max-width: 1600px) {
    .header-nav-button.two {
        display: none;
    }

    .header-nav-button.m-two {
        display: inherit
    }

    .mobile-header-nav-buttons.shown {
        left: 70%;
        width: 30%; 
    }
}

@media(max-width: 1400px) {
    .header-nav-button.three {
        display: none;
    }

    .header-nav-button.m-three {
        display: inherit
    }

    .mobile-header-nav-buttons.shown {
        left: 65%;
        width: 35%; 
    }
}

@media(max-width: 1200px) {
    .header-nav-button.four {
        display: none;
    }

    .header-nav-button.m-four {
        display: inherit
    }

    .mobile-header-nav-buttons.shown {
        left: 60%;
        width: 40%; 
    }
}

@media(max-width: 1000px) {
    .primary-nav-button.five {
        display: none;
    }

    .primary-nav-button.m-five {
        display: inherit
    }

    .mobile-header-nav-buttons.shown {
        left: 55%;
        width: 45%; 
    }
}

@media(max-width: 800px) {
    .mobile-header-nav-buttons.shown {
        display: fixed;
    }

    .mobile-header-nav-buttons.shown {
        left: 0;
        width: 100%; /* Full width of the screen */
    }

    .mobile-nav-dropdown.shown {
        font-size: 70px;
    }

    .mobile-nav-dropdown.shown:hover {
        font-size: 75px;
        padding: 3px;
        color: orange;
    }

    .mobile-nav-dropdown.hidden:hover {
        font-size: 85px;
        padding: 3px;
        color: orange;
    }

    .header-bar {
        justify-content: space-between;
    }

    .header-nav-buttons {
        display: none;
    }

    .header-site-title {
        font-size: 45px;
    }
    .site-title-bg {
        min-width: 420px;
    }

}

@media (max-width: 450px) {
    .header-site-title {
        font-size: 35px;
    }

    .site-title-bg {
        min-width: 300px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .mobile-nav-dropdown.shown {
        font-size: 40px;
    }

    .mobile-nav-dropdown.shown:hover {
        font-size: 45px;
        padding: 3px;
        color: orange;
    }

    .mobile-nav-dropdown.hidden {
        font-size: 40px;
        padding: 3px;
        color: black;
    }

    .mobile-nav-dropdown.hidden:hover {
        font-size: 43px;
        padding: 3px;
        color: orange;
    }

    .header-bar {
        height: 80px;
    }
}

@media (max-width: 250px) {
    .header-site-title {
        font-size: 35px;
    }

    .site-title-bg {
        min-width: 180px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
