.inner-experience-text {
    display: block;
    width: 50%;
    min-width: 800px;
    color: white;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
}

.all-projects-link {
    text-decoration: none;
}

.project-disclaimer-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.project-disclaimer {
    width: 50%;
    font-size: 25px;
    color: white;
}

.disclaimer-inline-link {
    color: rgb(255, 186, 57);
}

@media (max-width: 1100px) {
    .project-disclaimer {
        width: 90%;
    }
    .inner-experience-text {
        width: 95%;
        min-width: 0px;
    }
}

.body-text-wrapper {
    color: #fff;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 20px;
}

.section-header-text {
    margin-bottom: 0px;
    margin: 0px;
    color: #fff;
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    color: rgb(255, 186, 57);
}

.project-header-wrapper {
    max-width: 50%;
    min-width: 800px;
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 30px;
}

.outer-project-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 1100px) {
    .project-header-wrapper {
        min-width: 0px;
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .section-header-text {
        font-size: 50px;
    }
}
