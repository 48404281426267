.circle-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: relative;
}


.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;

    border-style: solid;
    background-color: #35363a;

    border-radius: 50%;
    border-color: white;
    border-width: 3px;

    cursor: pointer;
    transition: border-color 0.3s;
    z-index: 1;
}

.circle.selected {
    border-color: orange;
    /* background-color: orange; */
}

.line {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 5px;
    background-color: white;
    position: absolute;
    top: 50%;
    margin-top: -2.5px;
    z-index: 0;
}

.line-section {
    width: calc(100% / 3);
    height: 5px;
    background-color: white;
    transition: background-color 0.3s;
}

.line-section.selected {
    background-color: orange;
}

.circle-labels {
    display: flex;
    width: 100%;
    margin-top: 4px;
    justify-content: space-around;
}

.circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;

    margin-top: 15px;
}

.discord-icon {
    font-size: 60px;
    transition: font-size 0.3s;
}

.discord-icon.selected {
    font-size: 65px;
}

@media (max-width: 600px) {
    .circle {
        width: 80px;
        height: 80px;
    }

    .circle-container {
        font-size: 20px;
    }

    .discord-icon {
        font-size: 45px;
    }

    .discord-icon.selected {
        font-size: 50px;
    }
}

@media (max-width: 420px) {
    .circle {
        width: 60px;
        height: 60px;
    }

    .circle-container {
        font-size: 15px;
    }

    .discord-icon {
        font-size: 25px;
    }

    .discord-icon.selected {
        font-size: 30px;
    }
}
