.chess-game-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 20px;
    margin-bottom: 20px;

    min-width: 1000px;
    width: 60%;
    background-color: grey;
    padding: 10px;
    border: 1px solid rgb(255, 123, 123);
}

.inner-chess-game-wrapper {
    width: 80%;
    max-width: 800px;
}

.chess-board-tip {
    font-size: 23px;
    padding: 0px;
    margin: 5px;
    text-align: center;
    margin-top: 15px;
}

.chess-game-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.chess-profile-pic {
    border-radius: 40%;
    width: 75px;
    height: 75px;
}
.chess-game-player {
    display: flex;
    align-items: center;
}

.chess-game-player-username {
    margin: 0px;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 35px;
}

.chess-game-header {
    background-color: rgb(128, 128, 128);
    margin-bottom: 10px;
}

@media (max-width: 1050px) {
    .chess-game-wrapper {
        min-width: 95%;
    }
}

@media (max-width: 700px) {
    .inner-chess-game-wrapper {
        width: 95%;
    }

    .chess-game-player-username {
        font-size: 28px;
    }
}

@media (max-width: 570px) {
    .chess-game-player-username {
        font-size: 22px;
    }
}

@media (max-width: 500px) {
    .chess-profile-pic {
        display: none;
    }
}

@media (max-width: 350px) {
    .chess-game-player-username {
        font-size: 19px;
    }
}
