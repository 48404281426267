.separator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0rem;
    margin-top: 2rem;
}

.separator-line {
    width: 100%;
    height: 1px;
    background-color: orange;
}

.separator-title {
    position: relative;
    top: -1.4rem;
    background-color: #35363a;
    padding: 0 0.5rem;
}

.separator-title span {
    color: orange;
    font-weight: bold;
    font-size: 2.2rem;
}
