.outer-tech-bubble {
    display: inline-block;
    margin: 5px;
}
.tech-bubble-link {
    color: white;
    text-decoration: none;
}
.tech-name {
    font-size: 1.1rem;
    margin: 0px;
}
.tech-logo {
    /* width: 33px; */
    height: 33px;
    margin-right: 5px;
}

.react-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #525252;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #00aaff
}

.node-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #26a526;
    color: black;
}

.firebase-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #525252;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #f0bd15
}

.python-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #525252;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #00aaff
}

.rust-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #a55f26;
    color: black;
}

.cpp-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #525252;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #00aaff
}

.c-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #525252;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #00aaff
}

.kotlin-tech-bubble {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 15px;
    border: 2.5px solid #c01df1;
    color: black;
}
