.harlough-nl-wrapper {
    width: 50%;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 30px;
}


.harlough-nl-img {
    max-width: 1100px;
    width: 90%;
}

@media (max-width: 500px) {
    .harlough-nl-img {
        width: 100%;
    }
}

.img-caption {
    font-size: 20px;
    margin: 0px;
    margin-top: 10px;
    color: #ccc9c2;
}

@media (max-width: 1100px) {
    .harlough-nl-wrapper {
        width: 100%;
        min-width: 0px;
        margin: 0px;
    }
}
